<template>
    <div class="w-full card">
        <header class="flex justify-between border-b-1 border-solid border-gray-500">
            <h1>{{ $t('employee_details.address') }}</h1>
        </header>
        <hr class="my-4">
        <section>
            <el-row :gutter="15">
                <el-col :span="15">
                    <el-form ref="form" label-position="top" :model="addressData" :rules="validationRules">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item :label="$t('common.google_map_search')">
                                    <AutoComplete @placeFound="placeFound" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="15">
                            <el-col :span="12">
                                <el-form-item label="CO">
                                    <el-input v-model="addressData.co" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('employee_details.street')" prop="street">
                                    <el-input v-model="addressData.street" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="15">
                            <el-col :span="12">
                                <el-form-item :label="$t('employee_details.city')" prop="city">
                                    <el-input v-model="addressData.city" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('employee_details.post_code')" prop="post_code">
                                    <el-input v-model="addressData.post_code" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
                <el-col :span="9">
                    <div class="google-maps-container">
                        <gmap-map
                            ref="maps"
                            :center="{ lat:Number(addressData.map_lat), lng:Number(addressData.map_lng) }"
                            :zoom="14"
                            map-type-id="terrain"
                            style="height: 595px"
                            :options="{
                                scrollwheel: false
                            }"
                        >
                            <gmap-marker
                                :key="10"
                                :position="{ lat:Number(addressData.map_lat), lng:Number(addressData.map_lng)}"
                                :clickable="false"
                                :draggable="true"
                                @dragend="markerDragStart"
                            />
                            <gmap-circle
                                :center="{lat:Number(addressData.map_lat), lng:Number(addressData.map_lng)}"
                                :radius="addressData.geofence_radius"
                                :options="{
                                    fillColor: '#77b4ff',
                                    fillOpacity: 0.2,
                                    strokeColor: '#7eb4ff',
                                    strokeWeight: 1,
                                    clickable: false
                                }"
                            />
                        </gmap-map>
                    </div>
                </el-col>
            </el-row>
        </section>
        <hr class="my-4">
        <footer class="flex justify-end">
            <el-button type="success" :loading="$wait.is('updating')" @click="update">
                {{ $t('common.update') }}
            </el-button>
        </footer>
    </div>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    installComponents: true,
    load:              {
        key:       'AIzaSyCNIRpGZdH8CTBizW-HcGlAhnO_8YHvRNg',
        libraries: 'places,geometry',
        language:  'se',
    },
});

export default {
    components: {
        AutoComplete: () => import(/* webpackChunkName: "GoogleAddressAutoComplete" */ '@/components/GoogleMapSearch/AutoComplete.vue'),
    },

    data() {
        return {
            employeeUuid: this.$route.params.uuid,
            addressData:  {
                map_lat: 59.3293235,
                map_lng: 18.0685808,
            },
            validationRules: {
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'address');
    },

    created() {
        this.getDetails();
    },

    methods: {
        async getDetails() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/address_details`);
            this.addressData = data;

            this.$wait.end('loading');
        },

        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('updating');

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/address/${this.addressData.uuid}`, {
                co:        this.addressData.co,
                street:    this.addressData.street,
                post_code: this.addressData.post_code,
                city:      this.addressData.city,
                map_lat:   this.addressData.map_lat,
                map_lng:   this.addressData.map_lng,
            });

            this.$notify.success({ title: 'Success' });
            this.$wait.end('updating');
        },

        markerDragStart(event) {
            this.$set(this.addressData, 'map_lat', event.latLng.lat());
            this.$set(this.addressData, 'map_lng', event.latLng.lng());
        },

        placeFound(data) {
            this.addressData.post_code = data.postcode;
            this.addressData.city = data.city;
            this.addressData.street = data.street;
            this.addressData.map_lat = data.map_lat;
            this.addressData.map_lng = data.map_lng;
        },
    },
};
</script>
